<template>
  <div class="p-4 pb-1">
    <h3 class="font-weight-bold my-auto pb-2">{{ ((selectedYear == -1 && selectedArticle == -1) || years.length == 0)? "Aktuelles" : (selectedYear != -1)? years.filter(year => year.id == selectedYear)[0].name : selectedArticleObj.title }}</h3>
  
    <!-- Loading spinner -->
    <div v-if="gettingData && (years.length == 0 || selectedArticle > -1 || selectedYear > -1)" class="thumb-spinner d-flex justify-content-center my-3" style="position: relative">
      <b-spinner class="align-self-center" variant="primary"></b-spinner>
    </div>
    <b-alert v-else-if="error" class="m-3 mt-4 text-center" variant="danger" show>
      {{error}}
    </b-alert>

    <!-- Year view  -->
    <b-row v-else-if="selectedArticle == -1 && selectedYear == -1" align-h="center" class="mt-4">
      <b-col v-for="year in years" :key="year.id" cols="auto" class="mb-4">
        <!-- <b-btn style="width: 10rem;">{{ year.name }}</b-btn> -->
        <router-link :to="'aktuelles#y' + year.id" >
          <div class="year-item-container">
            <div class="year-item shadow" :style="{ backgroundImage: `url(${year.thumbLocation})` }"
							style="background-color: rgb(var(--custom-grey));">
            </div>
            <div class="year-item-overlay">
            </div>
            <div class="year-item-text d-flex justify-content-center align-items-center">
              <h3 class="font-weight-bold mb-0">{{ year.name }}</h3>
            </div>
          </div>
        </router-link>
      </b-col>
    </b-row>

    <!-- Article list view -->
    <b-list-group v-else-if="selectedYear != -1" class="mt-3">
      <b-list-group-item v-for="article in articles" :key="article.id" 
        :to="'aktuelles#a' + article.id" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-2">{{ article.title }}</h5>
          <small style="min-width: 6rem; text-align: right;">{{ parseDate(article.publishDate) }}</small>
        </div>

        <p style="line-height: 1.3" class="mb-1">
          <small>
            {{ article.preview }}
            <br>
            . . .
          </small>
        </p>
      </b-list-group-item>
    </b-list-group>

    <!-- Article view  -->
    <div v-else v-html="selectedArticleObj.htmlContent" class="my-3 ck-content">
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
		this.years = JSON.parse(localStorage.getItem('aktuelles-years')) ?? [];

    this.fetchYears();

    if(location.hash.substr(1, 1) == 'y') {
      this.selectedYear = parseInt(location.hash.substr(2, location.hash.length - 2));
      this.fetchArticleList(this.selectedYear);
    } else if(location.hash.substr(1, 1) == 'a') {
      this.selectedArticle = parseInt(location.hash.substr(2, location.hash.length - 2));
      this.fetchArticle(this.selectedArticle);
    }
  },
  data() {
    return {
      articles: [],
      years: [],
      selectedYear: -1,
      selectedArticle: -1,
      selectedArticleObj: null,
      gettingData: true,
      error: ''
    }
  },
  watch: {
    $route: function(to) {
      window.scroll({ x: 0, y: 0, behavior: 'smooth' });

      this.selectedYear = -1;
      this.selectedArticle = -1;

      if(!to.hash)
        return; 
      
      if(to.hash.substr(1, 1) == 'y') {
        this.selectedYear = parseInt(to.hash.substr(2, location.hash.length - 2));
        this.fetchArticleList(this.selectedYear);
      } else if(location.hash.substr(1, 1) == 'a') {
        this.selectedArticle = parseInt(location.hash.substr(2, location.hash.length - 2));
        this.fetchArticle(this.selectedArticle);
      }
    }
  },
  methods: {
    fetchYears: async function() {
      this.gettingData = true;
      this.error = '';

      try {
        let result = await this.axios.get('/api/articleyear');
        console.log(result);

        this.years = result.data ?? [];
        this.years = this.years.filter(e => e.visible);

				// sort years
				this.years.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

				// save cache
				localStorage.setItem('aktuelles-years', JSON.stringify(this.years));
      } catch (error) {
        console.log(error);
        this.error = 'Fehler beim Laden der Berichte. Bitte versuch es später erneut.';
      }

			if(process.env.NODE_ENV === 'development') {
        this.error = null;

				// eslint-disable-next-line no-useless-escape
				this.years = [{"id":"2","name":"2019","thumbLocation":"\/images\/articles\/einloesungersterpreis-3.jpg","visible":true},{"id":"3","name":"2015\/16","thumbLocation":"","visible":true},{"id":"4","name":"2017","thumbLocation":"\/images\/articles\/Adventsglhen-17.jpeg","visible":true},{"id":"5","name":"2018","thumbLocation":"\/images\/articles\/WhatsApp-Image-2018-04-25-at-17.16.00.jpeg","visible":true}]
			}

      this.gettingData = false;
    },
    fetchArticleList: async function(year) {
      this.gettingData = true;
      this.error = '';

      try {
        let result = await this.axios.get('/api/article', {
          params: {
            year: year,
            nocontent: true
          }
        });
        console.log(result);

        this.articles = result.data ?? [];
        this.articles = this.articles.filter(e => e.visible);
				this.articles.sort((a, b) => a.publishDate - b.publishDate);
      } catch (error) {
        console.log(error);
        this.error = 'Fehler beim Laden der Berichte. Bitte versuch es später erneut.';
      }

			if(process.env.NODE_ENV === 'development') {
        this.error = null;

				// eslint-disable-next-line no-useless-escape
				this.articles = [{"id":"11","title":"Besuch der Ignition 2017","year":"4","visible":true,"publishDate":"1489100400000","isSpecial":null,"editSource":false,"preview":"Am 9.3.2017 besuchten wir die Gr\u00fcndermesse Ignition in Erfurt. Zwei weitere Sch\u00fclerfirmen aus Erfurt teilten sich den Stand mit uns. Nach einiger Zeit sprachen uns die ersten Leute"},{"id":"12","title":"Adventsgl\u00fchen Stadtroda 2017","year":"4","visible":true,"publishDate":"1512255600000","isSpecial":null,"editSource":false,"preview":"Am Samstag, den 2.12. fand das Adventsgl\u00fchen in Stadtroda statt. Wir waren dort mit einem Stand vertreten, in welchem wir Flyer, Visitenkarten, uvm. verteilten. Das war aber nicht"},{"id":"13","title":"Weihnachtskonzert J.H.P Gymnasium Stadtroda 2017","year":"4","visible":true,"publishDate":"1513209600000","isSpecial":null,"editSource":false,"preview":"Am 13.12. organisierte unsere Schule ein Weihnachtskonzert mit anschlie\u00dfendem Weihnachtsmarkt. Nat\u00fcrlich waren auch wir, die JHP-Company, mit einem Stand beim Weihnachtsmarkt vertreten."}];
			}

      this.gettingData = false;
    },
    fetchArticle: async function(id) {
      this.gettingData = true;
      this.error = '';

      try {
        let result = await this.axios.get('/api/article/' + id);
        console.log(result);
        this.selectedArticleObj = result.data;
      } catch (error) {
        console.log(error);
        this.error = 'Fehler beim Laden des Artikels';
      }

      this.gettingData = false;
    },
    dateToDDMMYYYY: function(date) {
        let dateObj = new Date(parseInt(date));

        var day = dateObj.getDate();
        var month = dateObj.getMonth();
        month = month + 1;
        if((String(day)).length == 1)
        day = '0' + day;
        if((String(month)).length == 1)
        month = '0' + month;

        return (day+ '.' + month + '.' + dateObj.getFullYear());
    },
    parseDate: function(date) {
      const diffTime = Math.abs(new Date() - new Date(parseInt(date)));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1; 

      if(diffDays > 6)
        return this.dateToDDMMYYYY(date);
      else if(diffDays <= 6 && diffDays != 0)
        return `Vor ${diffDays} ${(diffDays > 1)? 'Tagen' : 'Tag'}`;
      else
        return 'Vor kurzem';
    }
  }
}
</script>

<style scoped>
.year-item-container {
  position: relative;
  width: 8rem; 
  height: 8rem; 
}

.year-item {
  width: 8rem; 
  height: 8rem; 
  border-radius: 0.3rem;
  background-size: cover;
  background-position: center;
  position: relative;
	transition: 0.15s;
}

.year-item-overlay {
  background-color: var(--custom-white); 
  position: absolute; 
  top: 0; 
  left: 0;
  width: 8rem; 
  height: 8rem; 
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  opacity: 0.4;
  transition: 0.2s
}

a:hover .year-item-overlay {
  opacity: 0.6;
}
a:hover .year-item {
	filter: blur(2px);
}

.year-item-text {
  position: absolute; 
  top: 0; 
  left: 0;
  width: 8rem; 
  height: 8rem; 
  border-radius: 0.25rem;
  color: var(--custom-black);
}
</style>